import { h } from 'preact';

import './Footer.scss';

const Footer = () => (
  <footer>
  </footer>
);

export default Footer;
